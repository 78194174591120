import { useNavigate } from 'react-router-dom';
import DragAndDropList from '../List/List';
import { List, Card, Modal, Button, Checkbox, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { changeRuleOrder, deleteRule, flipRule, getRules } from '../../utils/api';
import { flipActiveRule } from '../../utils/endpoints';
import { DeleteOutlined, DragOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { UilEllipsisV, UilTrash } from '@iconscout/react-unicons'
import '../../App.css';
const { confirm } = Modal;



const MainPage = () => {
    const queryClient = useQueryClient();
    const { isError, isSuccess, isLoading, data, error } = useQuery({
        queryKey: ["rules"],
        queryFn: getRules,
        options: { staleTime: 10 * 20 * 100 }
    });

    const FlipActive = useMutation({
        mutationFn: flipRule,
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['rules'] })

            // Snapshot the previous value
            const previousRules = queryClient.getQueryData(['rules'])

            // Optimistically update to the new value
            queryClient.setQueryData(['rules'], (old) => {
                return old.map(e => e.id === ex.id ? { ...e, isActive: !e.isActive } : e);
            });

            // Return a context object with the snapshotted value
            return { previousRules }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['rules'], context.previousRules)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['rules'] })
        },
    })

    const DeleteSync = useMutation({
        mutationFn: deleteRule,
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['rules'] })

            // Snapshot the previous value
            const previousRules = queryClient.getQueryData(['rules'])

            // Optimistically update to the new value
            queryClient.setQueryData(['rules'], (old) => {
                return old.filter(e => e.id !== ex.id);
            });

            // Return a context object with the snapshotted value
            return { previousRules }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['rules'], context.previousRules)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['rules'] })
        },
    })

    const { mutate: changeOrder } = useMutation({
        mutationFn: changeRuleOrder,
        onMutate: async (newItems) => {
            await queryClient.cancelQueries({ queryKey: ['rules'] });
            const previousRules = queryClient.getQueryData(['rules']);
            queryClient.setQueryData(['rules'], newItems);
            return { previousRules };
        },
        onError: (err, newItems, context) => {
            queryClient.setQueryData(['rules'], context.previousRules);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['rules'] });
        },
    });

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(data);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        changeOrder(reorderedItems);
    };
    if (isLoading) {
        return (<>LOADING</>)
    }
    if (isError) return <div>Error: {error.message}</div>;
    return (<>

        <DragAndDropList items={data} onDragEnd={onDragEnd} renderItem={(item, provided) => {
            return (<Card
                // bodyStyle={{ padding: "6px", margin: "auto", background: "red" }}
                className="draggable-item"
                ref={provided.innerRef}
                {...provided.draggableProps}

            >
                <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <div {...provided.dragHandleProps} style={{ height: "100%" }}>
                        <UilEllipsisV color="#0092E4" size="35" style={{ margin: "auto" }}></UilEllipsisV>
                    </div>

                    <Tooltip placement="top" title={item.isDraft ? "You cannot make this rule active, because configuration is not finished in Linnworks" : "Determines whether rule will be executed on new orders or not"}>
                        <Checkbox

                            style={{
                                "--background-color": "#1890ff",
                                "--border-color": "#1890ff",
                                marginLeft: "10px"
                            }}
                            disabled={item.isDraft}
                            checked={item.isActive}
                            onClick={(e) => FlipActive.mutate(item)}
                        ></Checkbox>
                    </Tooltip>

                    <div style={{ flexGrow: 4, fontSize: "1.5em" }}>
                        {item.name}
                    </div>



                    <Button type="primary" style={{ width: "75px", height: "75px", background: "white", border: "4px solid #ff4d4f" }}
                        icon={<UilTrash color="#ff4d4f" size="40" ></UilTrash>}
                        onClick={() => {
                            confirm({
                                title: 'Please confirm you want to delete this sync.',
                                icon: <ExclamationCircleOutlined />,
                                content: 'This sync will be deleted permanently. If you want to stop it from running you can just pause it.',
                                okText: 'Yes, i want to delete this sync',
                                okType: 'danger',
                                cancelText: 'Back',

                                onOk() {
                                    DeleteSync.mutate(item);
                                },
                                onCancel() { },
                            });
                        }}>

                    </Button>
                </div>

            </Card >)
        }}></DragAndDropList >
    </>);
}

export default MainPage;