import axios from 'axios';
import { changeOrderOfRules, deleteRuleById, flipActiveRule, getAllRules } from './endpoints';

export const getRules = async () => {
    var res = await axios.get(getAllRules);
    return res.data;
}

export const deleteRule = async (exp) => {
    var res = await axios.post(deleteRuleById(exp.id));
    return res.data;
}

export const flipRule = async (exp) => {
    var res = await axios.post(flipActiveRule(exp.id, !exp.isActive));
    return res.data;
}

export const changeRuleOrder = async (reorderedItems) => {
    const response = await axios.post(changeOrderOfRules, [...reorderedItems]);
    return response.data;
};

