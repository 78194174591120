export const authorize = '/api/Authorization/authorizeByApplication/';
export const getAllRules = '/api/Rules';

export const flipActiveRule = (id, isActive) => `/api/Rules/${id}/${isActive}`;

export const deleteRuleById = (id) => `/api/Rules/delete/${id}`;

export const changeOrderOfRules = '/api/Rules/Order';


